@font-face {
  font-family: Tempting;
  src: url(/public/Tempting2.ttf);
}
/* 697351 Green*/ 
/* EE9A6E Naranja*/ 
/* E48080 PaloRosa*/ 
/* F7D2D7 Rosado*/ 
/* F4F3EF Blanco*/ 
:root {
  --font-family-alt2: 'Lora', serif;
  --font-family-title: 'Parisienne', serif;
  --font-family-highlight: 'Playfair Display', serif;
  --font-family-heading: 'Tempting', sans-serif;
  --font-family-alt: 'Dancing Script', sans-serif;
  --font-family-base: 'Josefin Slab', serif;
  --color-primary: #F4F3EF;
  --color-primary-hover: #32190b;
  --color-primary-active: #32190b;
  --color-secondary: #697351;
  --color-secondary-hover: #697351;
  --color-secondary-active: #697351;
  --color-tertiary: #EE9A6E;
  --color-tertiary-hover: #EE9A6E;
  --color-tertiary-active: #EE9A6E;
  --color-success: #E48080;
  --color-success-hover: #E48080;
  --color-success-active: #E48080;
  --color-error: #d8292f;
  --color-error-hover: #b21717;
  --color-error-active: #8b1515;
  --color-overlay: rgba(76, 37, 23, 0.336);
  --font-size-base: 14px;
  --font-size-base-mobile: 14px;
  --font-size-main: 12px;
  --font-size-main-mobile: 14px;
  --font-size-secondary: 14px;
  --font-size-secondary-mobile: 14px;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
}

.bg-green {
  background-color: var(--color-secondary);
}

.bg-palo {
  background-color: var(--color-success);
}

.bg-orange {
  background-color: var(--color-tertiary);
}

.bg-rosao {
  background-color: var(--color-primary);
}


.title-text {
  font-family: var(--font-family-title);
}

.base-text {
  font-family: var(--font-family-base);
}

.highlight-text {
  font-family: var(--font-family-highlight);
}

.heading-text {
  font-family: var(--font-family-title);
}